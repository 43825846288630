
























































import { Component, Vue } from 'vue-property-decorator';
import {WorkspaceAvailable, CreateWorkspaceMutation} from '@/graphql/workspace';
import {MeQuery} from "@/graphql/auth";

@Component({
  components: {},

  apollo: {
    domain: {
      query: WorkspaceAvailable,
      variables() {
        return {
          name: this.domainName,
        };
      },
      update: data => data.workspaceAvailable.domain,
      skip() {
        return this.domainName === null || this.domainName.length < 3;
      },
    },
  },
})
export default class WorkspaceCreate extends Vue {
  createWorkspaceInput: string | null = null;
  clicked = false;
  domain: string | null = null;
  selectedPermission: string | null = 'workspace';
  hasStatus: boolean = false;
  referralCode: string | null = null;
  isLoading: boolean = false;

  get me() {
    return this.$store.state.me;
  }

  mounted() {
    this.$store.commit('set_newly_created_northstar_count', null);
    this.$nextTick(function () {
      //@ts-ignore ToDo: fix type issue
      this.$refs.mainCreate.focus();
    });
  }

  get infoWorkspaceCreate() {
    return 'You can name the workspace anything you want, but we recommend using a name that \n' +
        'refers to your team or company – so that everyone in the workspace directly recognises it.'
  }

  get domainName() {
    return this.workspaceDemoName;
  }

  get workspaceDemoName() {
    if(this.createWorkspaceInput) {
      return this.createWorkspaceInput;
    } else {
      return 'Workspace';
    }
  }

  createWorkspace() {
    this.isLoading = true;
    
    this.clicked = true;
    if(this.$route.query.referral) {
      //@ts-ignore
      this.referralCode = this.$route.query.referral;
     
    }
    this.$apollo
      .mutate({
        mutation: CreateWorkspaceMutation,
        variables: {
          name: this.workspaceDemoName,
          domain: this.domain,
          referral_code: this.referralCode
        },
        refetchQueries:[{query: MeQuery}]
      })
      .then(({ data: { createWorkspace: workspace } }) => {
        console.log(workspace)
        this.$gtag.event('pmb_user_added_workspace');
        this.$store.commit('set_workspace', workspace);
        this.$store.commit('set_active_workspace_pid', workspace.pid);
        this.$store.commit('set_active_workspace_access', workspace.my_access);
        this.$router.push({name: 'create-board', params: { workspace_pid: this.$store.state.workspace.pid } })
        //this.$router.push({name: 'workspace-onboarding'})
        //this.$router.push({ name: 'dashboard-layout', params: { workspace_pid: workspace.pid } });
        this.isLoading = false;

      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        this.clicked = false;
      });
  }
}
